import { PageProps } from 'gatsby';
import { useDecision } from '@optimizely/react-sdk';

import { useCustomMixpanel } from '@services/Mixpanel';
import { BoilerDetailsPage } from '@src/modules';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { NewBoilerDetailsPage } from '@src/modules/BoilerDetails/NewBoilerDetailsPage';

const Boiler = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();
  const [{ enabled: isQuotesPlatform }] = useDecision(OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT);

  if (isQuotesPlatform) {
    return <NewBoilerDetailsPage location={props.location} mixpanel={mixpanel} />;
  }

  return <BoilerDetailsPage {...props} mixpanel={mixpanel} />;
};

export default Boiler;
